import axios from 'axios';
import { useGlobalState } from 'src/state/globalstate';


//URL fo API request
export const Axios = axios.create({
  // baseURL: 'https://mbotapi.inabia.com/api', //Staging
  // baseURL: 'https://regtoolapi.inabia.com/api', //regtoolapi
  // baseURL: 'https://regtoolapistag.inabia.com/api', //Staging2
  // baseURL: 'https://regtoolapi.allergan.com/api', //Client
  // baseURL: `${window.location.origin.toString()}:3000/api`, //Dynamic Paths
  // baseURL: 'https://api.inabia.com/api',
  // baseURL: 'https://biosapi.inabia.com/api', //biosapi
  // baseURL: 'https://biosapi.inabia.com/api', //ark staging
  // baseURL: 'https://arkapi.inabia.com/api', //ark production
  baseURL: 'https://arkapistag.inabia.com/api', //ark staging
  
  headers: {
    'Content-type': 'application/json'
  }
});

//Project Name
export const projectName = {
  Path: 'Ark',  //Ark
  // Path: 'Bios', //biosnpm
  // Path: 'Regtool',  //regtool
}


export const S3bucket = {}

export const Segment= {
  tracking: false //true or false
}


//Check for file statuses
export const AxiosNetwork = axios.create({
  baseURL: 'https://inabia.com/inabia-ai-crobjob',
  headers: {
    'Content-type': 'application/json'
  }
});

//Initialize user login parameters for API calls
Axios.interceptors.request.use((config) => {
  const userId = localStorage.getItem('userId');
  let langId = '2';
  let langType = localStorage.getItem('languageType');
  if(langType) {
    langId = langType;
  }

  const token = localStorage.getItem('token');
  const times = Date.now()
  if (userId && token) {
    config.params = {
      userId,
      token,
      times,
      langId,
      ...config.params
    };
  } else if (!userId && !token) {
    const token = 'sdddwddwdwdwwd';
    config.params = {
      times,
      langId,
      ...config.params
    };
  }
  return config;
});

// Ark Api Calls
export enum API {
  AddCase = '/addCase',
  GetCases = '/cases',
  FileUpload = '/fileUpload',
  TagsUpdate = '/tagsUpdate',
  Edit = '/edit',
  Docs = '/docsSQL',
  NetworkError = '/ediscoveryAPI.php',
  forgotPassAWS = '/forgotPassAWS',
  forgotPassCodeAWS = '/forgotPassCodeAWS',
  Files = '/filesSQL',
  eFiles = '/excelDocsNew',
  loginAWS = '/loginAWSSQL',
  registerAWS = '/signupAWSSQL',
  AWSauth = '/AWSauth',
  signupAWSCode = '/signupAWSCode',
  Search = '/advanceSearchFiles',
  CaseSettings = '/case-settings',
  changePassAWS = '/changePassAWS',
  Viewer = '/viewerSQL',
  Addtags = '/tagsUpdate',
  deleteCase = '/deleteCase',
  editCase = '/editCase',
  deleteDocs = '/deleteDocsSQL',
  DocType = 'docsGraphBardocType',
  columnSelection = 'columnSelection',
  VersionCheck = 'updateVersionSQL',
  GetConfig = 'formatList',
  updateUserFormat = 'updateUserFormat',
  fileUploadS3 = 'fileUploadS3MbotSQL',
  S3UploadPath = 'getS3BucketName',
  ResendVerificationCode = "resendConfirmationCodeAWS",
  statusService = 'statusService',
  createService = 'createService',
  melasticsearch = 'elasticsearch',
  tagsMlrc = 'tagsSQL',
  postTags = 'postTagsSQL',
  docTypeMlrc = 'docTypeSQL',
  postType = 'postTypeSQL',
  fileProcess = 'fileProcessSQL',
  postDeleteTags = 'postDeleteTagsSQL',
  statusServiceStaging = 'statusServiceStaging',
  createServiceStaging = 'createServiceStaging',
  getCommercials= 'getCommercials',
  updateStatusStopped = 'updateStatusStopped',
  fileUploadS3RealTime = 'fileUploadS3RealTimeSQL',
  docsRealTime = 'docsRealTimeSQL',
  addThumbsUp = 'addThumbsUp',
  viewThumbsUp = 'viewThumbsUpSQL',
  addThumbsUpNew = 'addThumbsUpLatest',
  updateThumbsUp = 'updateThumbsUpSQL',
  userDetails = 'userDetailsSQL',
  rules = 'rulesStaging',
  viewThumbsUpDownload = 'viewThumbsUpDownloadSQL',
  addThumbsUpLatestSQL = 'addThumbsUpLatestSQL',
  deleteThumbsUp = 'deleteThumbsUp',
  viewThumbsUpUsersSQL = 'viewThumbsUpUsersSQL',
  PDFListing = 'PIIviewerDocList',
  mlrcBedrock = 'Bedrock',
  userPreferences = 'userPreferences',
  realTimeTextProcess= 'realTimeTextProcess',
  imageUploadS3MbotSQL = 'imageUploadS3MbotSQL',
  imageMlrcSQL = 'imageSQL',
  imageProcessSQL = 'imageProcessSQL',
  getUserPref = 'getUserPref',
  deleteImgSQL = 'deleteImgSQL',
  updateStatusImgStopped = 'updateStatusImgStopped',
  addRulesStaging = 'addRulesStaging',
  updateRulesStaging = 'updateRulesStaging',
  viewThumbDelete = 'viewThumbDelete',
  readS3Json = 'readS3Json',
  realTimeImageSearch = 'realTimeImageSearch',
  statusServiceImage = 'statusServiceImage',
  adminIds = 'adminIds',
  aaGuidelines = 'aaGuidelines'
}

// Regtool Api Calls
// export enum API {
//   AddCase = '/addCase',
//   GetCases = '/cases',
//   FileUpload = '/fileUpload',
//   TagsUpdate = '/tagsUpdate',
//   Edit = '/edit',
//   Docs = '/docsMlrcSQL',
//   NetworkError = '/ediscoveryAPI.php',
//   forgotPassAWS = '/forgotPassAWS',
//   forgotPassCodeAWS = '/forgotPassCodeAWS',
//   Files = '/filesMlrcSQL',
//   eFiles = '/excelDocsNew',
//   loginAWS = '/loginAWSSQL',
//   registerAWS = '/signupAWSSQL',
//   AWSauth = '/AWSauth',
//   signupAWSCode = '/signupAWSCode',
//   Search = '/advanceSearchFiles',
//   CaseSettings = '/case-settings',
//   changePassAWS = '/changePassAWS',
//   Viewer = '/viewerSQL',
//   Addtags = '/tagsUpdate',
//   deleteCase = '/deleteCase',
//   editCase = '/editCase',
//   deleteDocs = '/deleteDocsSQL',
//   DocType = 'docsGraphBardocType',
//   columnSelection = 'columnSelection',
//   VersionCheck = 'updateVersionSQL',
//   GetConfig = 'formatList',
//   updateUserFormat = 'updateUserFormat',
//   fileUploadS3 = 'fileUploadS3MbotSQL',
//   S3UploadPath = 'getS3BucketName',
//   ResendVerificationCode = "resendConfirmationCodeAWS",
//   statusService = 'statusService',
//   createService = 'createService',
//   melasticsearch = 'elasticsearch',
//   tagsMlrc = 'tagsMlrcSQL',
//   postTags = 'postTagsSQL',
//   docTypeMlrc = 'docTypeMlrcSQL',
//   postType = 'postTypeSQL',
//   fileProcess = 'fileProcessSQL',
//   postDeleteTags = 'postDeleteTagsSQL',
//   statusServiceStaging = 'statusServiceStaging',
//   createServiceStaging = 'createServiceStaging',
//   getCommercials= 'getCommercials',
//   updateStatusStopped = 'updateStatusStopped',
//   fileUploadS3RealTime = 'fileUploadS3RealTimeSQL',
//   docsRealTime = 'docsRealTimeSQL',
//   addThumbsUp = 'addThumbsUp',
//   viewThumbsUp = 'viewThumbsUpSQL',
//   addThumbsUpNew = 'addThumbsUpLatest',
//   updateThumbsUp = 'updateThumbsUpSQL',
//   userDetails = 'userDetailsSQL',
//   rules = 'rulesStaging',
//   viewThumbsUpDownload = 'viewThumbsUpDownloadSQL',
//   addThumbsUpLatestSQL = 'addThumbsUpLatestSQL',
//   deleteThumbsUp = 'deleteThumbsUp',
//   viewThumbsUpUsersSQL = 'viewThumbsUpUsersSQL',
//   PDFListing = 'PIIviewerDocList',
//   mlrcBedrock = 'mlrcBedrock',
//   userPreferences = 'userPreferences',
//   realTimeTextProcess= 'realTimeTextProcess',
//   imageUploadS3MbotSQL = 'imageUploadS3MbotSQL',
//   imageMlrcSQL = 'imageMlrcSQL',
//   imageProcessSQL = 'imageProcessSQL',
//   getUserPref = 'getUserPref',
//   deleteImgSQL = 'deleteImgSQL',
//   updateStatusImgStopped = 'updateStatusImgStopped',
//   addRulesStaging = 'addRulesStaging',
//   updateRulesStaging = 'updateRulesStaging',
//   viewThumbDelete = 'viewThumbDelete'
// }

export default Axios;
