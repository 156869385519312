import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import { Keywordcontext } from './context/keywordcontext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setGlobalState, useGlobalState } from './state/globalstate';
import IdleTimer from './session/idle';
import { version } from 'react';
import Axios, { API } from './common/api';
import * as crypto from 'crypto';
import React from 'react';
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

// import logo from './logo.svg';

declare global {
  interface Window {
    analytics: any;
  }
}

const App = () => {
  // // //console.log(version);
  const [keyword, setKeyword] = useState<string>('');
  const value = { keyword, setKeyword };
  const content = useRoutes(routes);
  var CryptoTS = require('crypto-ts');

  const [modalmessage] = useGlobalState('modalmessage');

  if (modalmessage) {
    modalmessage;
    setGlobalState('modalmessage', null);
  }

  const [loggedin] = useGlobalState('loggedin');

  //Remove console messages
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
    console.info = () => {};
    console.group = () => {};
    console.groupCollapsed = () => {};
    console.trace = () => {};
  } else if (process.env.NODE_ENV === 'development') {
    console.info = () => {};
    console.group = () => {};
    console.warn = () => {};
    console.groupCollapsed = () => {};
    console.trace = () => {};
  }

  //Get S3 Path
  useEffect(() => {
    let Count = 0;
    const getFilePath = async () => {
      try {
        await Axios.get(API.S3UploadPath).then((res) => {
          // store the data into our books variable
          setGlobalState('BucketType', res.data.s3BucketName.toString());
          setGlobalState('BucketPath', res.data.BucketPath.toString());
          var bytes = CryptoTS.AES.decrypt(res.data.accessKeyId, 'ARC TAH SHA');
          var accessKeyId = bytes.toString(CryptoTS.enc.Utf8);
          setGlobalState('accessKeyId', accessKeyId);
          var bytes = CryptoTS.AES.decrypt(
            res.data.secretAccessKey,
            'ARC TAH SHA'
          );
          var secretAccessKey = bytes.toString(CryptoTS.enc.Utf8);
          console.log(res.data.BucketRegion, secretAccessKey, accessKeyId)
          setGlobalState('secretAccessKey', secretAccessKey);
          setGlobalState('BucketRegion', res.data.BucketRegion);
          setGlobalState('ErrorFoundGlobal', true);
          // console.log('kEYS');
          // console.log(
          //   res.data.s3BucketName.toString(),
          //   res.data.BucketPath.toString(),
          //   accessKeyId,
          //   secretAccessKey
          // );
        });
      } catch (err) {
        setGlobalState('ErrorFoundGlobal', false);
        // getFilePath();
      }
    };
    getFilePath();

    // Get language parameters
    const Language = async () => {
      let selectedLanguage = localStorage.getItem('language');
      let jsonFile = 1;
      if (!selectedLanguage) {
        selectedLanguage = 'US - Commercial';
        localStorage.setItem('languageType', '2');
      }

      if (selectedLanguage == '中国 - 商业') {
        jsonFile = 1;
        localStorage.setItem('languageType', '1');
      } else if (selectedLanguage == 'US - Commercial') {
        jsonFile = 2;
        localStorage.setItem('languageType', '2');
      }

      const params = { commercialId: jsonFile };
      //Language listing
      try {
        await Axios.get(API.getCommercials, {
          params
        }).then(async (res) => {
          const languageResponse = res.data;
          console.log(languageResponse);
          setGlobalState('languageArray', languageResponse);
          localStorage.setItem('language', selectedLanguage);
          return Promise.resolve();
        });
      } catch (error) {
        // const ERROR_MSG = 'Unable to proceed with your request';
        // setGlobalState('modalmessage', toast.error(ERROR_MSG));
        return Promise.reject();
      }
    };
    Language();
  }, []);

  // useEffect(() => {
  //   addResponseMessage('Welcome to this Regtool Q chat!');
  // }, []);

  // const handleNewUserMessage = async (newMessage) => {
  //   let chatResponse = '';
  //   toggleMsgLoader();
  //   //console.log(`New message incoming! ${newMessage}`);
  //   const params = { question: encodeURIComponent(newMessage) };
  //   try {
  //     await Axios.get(API.mlrcBedrock, {
  //       params
  //     }).then((res) => {
  //       // store the data into our books variable
  //       //console.log(res.data.response);
  //       chatResponse = res.data.response;
  //       // let chatResponse = res.data;
  //     });
  //   } catch (err) {
  //     // setGlobalState('ErrorFoundGlobal', false);
  //     // //console.log(err);
  //   }

  //   addResponseMessage(chatResponse);
  //   toggleMsgLoader();
  //   // Now send the message throught the backend API
  // };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />

        <IdleTimer />
        <Keywordcontext.Provider value={value}>
          {content}
        </Keywordcontext.Provider>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <div className="App">
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            // profileAvatar={logo}
            title="Regtool Q"
            // subtitle="And my cool subtitle"
          />
        </div> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
